/* eslint-disable indent */
const routers = [
  {
    path: '/home/:qrcode',
    name: 'home',
    meta: {
      title: '首页',
      showBack: false,
      showMore: false,
    },
    component: (resolve) => require(['../components/home.vue'], resolve),
  },
  {
    path: '/paycenter/wxpay/:qrcode',
    name: 'paycenter_wxpay',
    meta: {
      title: '微信支付',
      showBack: false,
      showMore: false,
    },
    component: (resolve) => require(['../components/wxpay.vue'], resolve),
  },
  {
    path: '/paycenter/alipay/:qrcode',
    name: 'paycenter_alipay',
    meta: {
      title: '支付宝支付',
      showBack: false,
      showMore: false,
    },
    component: (resolve) => require(['../components/alipay.vue'], resolve),
  },
  {
    path: '/stop',
    name: 'stop',
    meta: {
      title: '禁止访问',
      showBack: false,
      showMore: false,
    },
    component: (resolve) => require(['../components/stop.vue'], resolve),
  }
]
export default routers
