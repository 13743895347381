var _env = {}
if (process.env.NODE_ENV === 'development') _env = require('./config.dev.js')
if (process.env.NODE_ENV === 'production') _env = require('./config.pro.js')

var config = {
  client: 'mobile_scan'
}

export default Object.assign(config, _env)
// module.exports = Object.assign(config, _env)
