<template>
  <div id="app" style="height:100%;">
    <router-view ref="view" style="height:100%;" class="router-view rv2"></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
  data () {
    return {
    }
  },
  directives: {
  },
  created: function () {
  },
  components: {
  },
  methods: {
  },
  computed: {
  }

}
</script>

<style lang="less">
@import './styles/gzstyle.less';

body {
  background-color: #fbf9fe;
}
html,
body {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  font-size: 14px;
  margin:0px;
  padding:0px;
}
</style>
