/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex'
import router from './router'
import { ajax, ajaxSync } from 'yesweb-ajax'
import serverConfig from './config/index'

var ajaxStore = {
  serverHost: serverConfig.serverHost,
  client: serverConfig.client,
  businessCode: '',
  user: {
    token: '',
    secretID: '',
    secretKey: 'JCKJ0377',
  },
}
const ajaxOption = {
  actionLogin(message) {
    console.log('子应用：跳转到登录窗口:' + message)
  },
  actionError(o) {
    alert(o.title + '\n' + o.message)
  },
  updateSecretKey(_data) {},
  axiosRequestConfig(config){
  }
}
ajax.init(ajaxStore, ajaxOption)

Vue.config.productionTip = false

Vue.use(Vuex)

let store = new Vuex.Store({
  state: {
    direction: 'forward',
    openID: '',
    qrCode:'',
    title:''
  },
  mutations: {
    updateDirection(state, payload) {
      state.direction = payload.direction
    },
    updateWXOpenID(state, openID) {
      state.openID = openID
    },
    updateTitle(state, title) {
      state.title = title
    },
    updateGZConfig(state, data) {
      ajaxStore.businessCode =  data.businessCode
    },
    updateQRCode(state,code){
      state.qrCode = code
    }
  },
})

Vue.prototype.$gzconfig = ajaxStore

new Vue({
  router,
  store,
  render: (h) => h(App),
  created() {
    var me = this
    this.applyAjaxConfig()

  },
  methods: {
    getQueryString(name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
      var r = window.location.search.substr(1).match(reg)
      if (r != null) {
        return unescape(r[2])
      }
      return null
    },
    applyAjaxConfig() {
      var vs = window.location.pathname.split('/')
      var qrCode = vs[vs.length - 1]
      this.$store.commit('updateQRCode', qrCode)

      var json = ajaxSync.get(this, '/onlinepay/H5Pay/GetBusinessCode', { qrCode });
      var res = JSON.parse(json);
      this.$store.commit('updateGZConfig', {
        businessCode:res.data.businessCode
      })
      
    }
  },
}).$mount('#app')
