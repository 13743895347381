import Vue from 'vue'
import Router from 'vue-router'
import routers from './routers'
Vue.use(Router)

function initRoot(e) {
  if (e.children) {
    e.children.forEach((element) => {
      initRoot(element)
    })
  }
}

function getRouters() {
  routers.forEach((element) => {
    initRoot(element)
  })
  return routers
}
export default new Router({
  mode: 'history', // history hash
  routes: getRouters(),
})
